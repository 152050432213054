<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
    <div class="container">
        <a class="navbar-brand" routerLink="/">ProDevLine</a><button class="navbar-toggler navbar-toggler-right" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><fa-icon class="ml-1" [icon]='["fas", "bars"]'></fa-icon></button>
        <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link" routerLink="/">Home</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/about">About</a></li>
                <!-- Log In Nav-Item - Only for Development Purposes -->
                <li class="nav-item" *ngIf="isDevEnv && !isLoggedIn"><a class="btn btn-danger" routerLink="/auth/login">Log In</a></li>
                <li class="nav-item ml-lg-2" *ngIf="isLoggedIn &amp;&amp; isOnPost"><a class="nav-link pointer" (click)="editPost()">Edit Post</a></li>
                <li class="nav-item ml-lg-2" *ngIf="isLoggedIn"><a class="btn btn-primary" routerLink="/new">New Post</a></li>
                <li class="nav-item ml-lg-4">
                    <div class="btn btn-warning p-1" style="border-radius: 10px;">
                        <fa-icon [icon]="darkModeIcon" (click)="setDarkMode()"></fa-icon>
                    </div>
                </li>
                <li ngbDropdown class="nav-item form-inline ml-lg-2" role="presentation">
                    <a class="nav-link pointer" ngbDropdownToggle *ngIf="isLoggedIn && userLoggedIn" 
                    style="border-radius: 20px;background: linear-gradient(to right, #26e45f, #15a735)">
                        {{ userLoggedIn }}
                    </a>
                    <div ngbDropdownMenu>
                        <!-- <a class="nav-link pointer" ngbDropdownItem routerLink="/user/profile">Profile</a> -->
                        <a class="nav-link pointer" ngbDropdownItem routerLink="/auth/changePassword">Change Password</a>
                        <div class="dropdown-divider"></div>
                        <a class="nav-link pointer" ngbDropdownItem (click)="logout()">Log Out</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
