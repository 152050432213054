<!-- Alert Modal -->
<div class="modal fade" id="{{ modalId + 'AlertModal'}}" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" 
      style="background-color: rgb(214, 55, 11); color: rgb(255, 255, 255);">
        <div class="modal-header alert alert-danger">
          <h5 class="modal-title" id="{{ modalId + 'AlertModalLabel' }}">An Error Occured</h5>
          <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center" id="{{ modalId + 'AlertModalBodyText' }}">
            <!-- Error Text Displays Here -->
            <ul id="{{ modalId + 'ErrorMessageslist' }}"></ul>
          </div>
          <br>
          <div class="d-flex justify-content-center" id="{{ modalId + 'AlertModalErrorStatusCode' }}">
            <!-- Error Status Code Displays Here -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
          </div>
      </div>
    </div>
  </div>