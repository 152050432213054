import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@common/models';
import { ConsentPayload } from '@common/models/consent.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

let _config: Config;

@Injectable({ providedIn: 'root' })
export class ConfigService {
    constructor(private http: HttpClient) {}

    loadConfig(): Promise<Config> {
        return this.http
            .get<Config>(`assets/config.json`)
            .pipe(
                tap(config => {
                    _config = config;
                })
            )
            .toPromise();
    }

    get config() {
        return _config;
    }

    consent$(consentPayload: ConsentPayload): Observable<void> {
        const requestOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            withCredentials: true
           };
        
        return this.http
            .post<void>(
                `${this.config.sbCleanBlogNodeURL}/api/latest/consent`,
                consentPayload, requestOptions 
            );
    }
}
