import { Component, Input, OnInit } from '@angular/core';
import { PopupsService } from '@common/services';
import { StringLiteral } from 'typescript';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  @Input() modalId: string = "default";

  constructor(
    private popupsService: PopupsService
    ) {
  }
  
  ngOnInit() {
  }

  closeModal(): void {
    this.popupsService.closeAlertModel('#' + this.modalId + 'AlertModal');
  }
}
