import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { CardViewDetailsComponent } from './card-view-details/card-view-details.component';
import { CardComponent } from './card/card.component';
import { ConsentBannerComponent } from './consent-banner/consent-banner.component';

export const components = [CardComponent, CardViewDetailsComponent, AlertModalComponent, ConsentBannerComponent];

export * from './card/card.component';
export * from './card-view-details/card-view-details.component';
export * from './alert-modal/alert-modal.component';
export * from './consent-banner/consent-banner.component';