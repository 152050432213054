import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'sb-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss'],
})
export class FooterComponent implements OnInit {
    constructor(private router: Router) {}
    ngOnInit() {}

    navigateToRoute(route: string) {
        // Navigate to the desired route
        this.router.navigate([route]).then(() => {
          // Scroll to the top of the page
          window.scrollTo(0, 0);
        });
      }
}
