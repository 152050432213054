import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class CsrfTokenService {
    private csrfTokenCookieName = 'XSRF-TOKEN';

    constructor(
        private cookieService: CookieService
        ) {}

    getStoredCsrfToken(): string | undefined {
        return this.cookieService.get(this.csrfTokenCookieName);
    }

    removeStoredCsrfToken(): void {
        this.cookieService.remove(this.csrfTokenCookieName);
    }
}