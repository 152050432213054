import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, UtilityService } from '@common/services';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { AuthUtilsService, CsrfTokenService } from '@modules/auth/services';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';
import { faSun as fasSun, faMoon as fasMoon } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'sb-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    userLoggedIn = "";
    isLoggedIn = false;
    isDevEnv = false;
    isOnPost = false;
    isMenuCollapsed = true;

    darkMode = false;
    darkModeIcon = 'moon';

    constructor(
        private navigationService: NavigationService,
        private authUtilsService: AuthUtilsService,
        private configService: ConfigService,
        private route: ActivatedRoute,
        private router: Router,
        private csrfTokenService: CsrfTokenService,
        library: FaIconLibrary,
        private utilityService: UtilityService
    ) {
        library.addIcons(fasSun, fasMoon);
    }

    ngOnInit() {
        if (this.utilityService.getStoredObject('darkModeSetting')) {
            this.darkMode = this.utilityService.getStoredObject('darkModeSetting') ?? false;
            if(this.darkMode) {
                this.addDarkModeTheme();
            }
            else {
                this.addLightModeTheme();
            }
        }
        else {
            this.utilityService.storeObject('darkModeSetting', this.darkMode);
        }
        
        this.subscription.add(
            this.navigationService.currentComponent$().subscribe(currentComponentName => {
                this.isOnPost = currentComponentName === 'PostComponent';
            })
        );
        this.subscription.add(
            this.authUtilsService.isLoggedIn$().subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
            })
        );

        // Used for adding a Log In Nav Item in the home page for easier navigation
        this.isDevEnv = this.configService.config.DevEnv;

        this.userLoggedIn = this.authUtilsService.userLoggedIn$();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    editPost() {
        this.router.navigateByUrl(`/edit/${this.route.snapshot.params.post}`);
    }

    setDarkMode() {
        this.darkMode = !this.darkMode;
        if (this.darkMode) {
            this.addDarkModeTheme();
        } else {
            this.addLightModeTheme();
        }

        this.utilityService.storeObject('darkModeSetting', this.darkMode);
    }

    addDarkModeTheme() {
        document.body.classList.add('dark-theme');
        document.body.querySelectorAll('.post-title').forEach(item => item.classList.add('dark-theme-post-preview'));
        document.body.querySelectorAll('.post-subtitle').forEach(item => item.classList.add('dark-theme-post-preview'));
        this.darkModeIcon = 'sun';
    }

    addLightModeTheme() {
        document.body.classList.remove('dark-theme');
        document.body.querySelectorAll('.post-title').forEach(item => item.classList.remove('dark-theme-post-preview'));
        document.body.querySelectorAll('.post-subtitle').forEach(item => item.classList.remove('dark-theme-post-preview'));
        this.darkModeIcon = 'moon';
    }

    logout() {
        // Implement JWT Authentication logout
        this.authUtilsService.removeTokens();
        this.csrfTokenService.removeStoredCsrfToken();
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
        this.router.navigateByUrl('/');
    }
}
