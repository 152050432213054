<footer>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto">
                <ul class="list-inline text-center">
                    <li class="list-inline-item">
                        <a (click)="navigateToRoute('/cookie-policy')" style="cursor: pointer"
                            ><fa-stack size="lg"><fa-icon class="fa-stack-2x" [icon]='["fas", "circle"]' stackItemSize="2x"></fa-icon><fa-icon class="fa-stack-1x fa-inverse" [icon]='["fas", "cookie"]' stackItemSize="1x"></fa-icon></fa-stack
                        ></a>
                    </li>
                </ul>
                <p class="copyright text-muted">Copyright © ProDevLine 2024</p>
            </div>
        </div>
    </div>
</footer>
