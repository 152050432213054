import { AppCommonService } from './app-common.service';
import { ConfigService } from './config.service';
import { PopupsService } from './popups.service';
import { PrismService } from './prism.service';
import { UtilityService } from './utility.service';

export const services = [AppCommonService, ConfigService, PrismService, UtilityService, PopupsService];

export * from './app-common.service';
export * from './config.service';
export * from './prism.service';
export * from './utility.service';
export * from './popups.service';
