import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class PopupsService {

  constructor() { }

  viewHttpErrorResponseMessagesInAlertModal(error: HttpErrorResponse, modalId: string): void {
    if(error.status == 0)
        {
          this.openAlertModal("The application could not contact the service!"
          , error.status, modalId);
        }
        else
        {
          if(error.error.errors) {
            this.openAlertModal(error.error.errors, error.status, modalId);
          } else {
            this.openAlertModal(error.error, error.status, modalId);
          }
        }
  }

  openAlertModal(errorMessages: any, errorStatusCode: number = -1, modalId: string): void {

    // clearing the list of messages from previous errors and messages
    var container = <HTMLElement>document.querySelector(modalId + 'ErrorMessageslist');
    container.innerHTML = "";

    if(typeof errorMessages === "string")
    {
      var listElement = document.createElement("li");
      listElement.innerHTML = errorMessages;
      container.appendChild(listElement);
    }
    else
    {
      if(errorMessages && errorMessages.status == '403') {
        var listElement = document.createElement("li");
          listElement.innerHTML = 'Forbidden Operation';
          container.appendChild(listElement);
      }
      else if(errorMessages && errorMessages.status == '401') {
        var listElement = document.createElement("li");
          listElement.innerHTML = 'Authorized Operation';
          container.appendChild(listElement);
      }
      else {
        for (const message in errorMessages) {
          var listElement = document.createElement("li");
          listElement.innerHTML = errorMessages[message];
          container.appendChild(listElement);
        }
      }
    }

    $(modalId + 'AlertModalErrorStatusCode').text("Error Code: " + errorStatusCode);
    $(modalId + 'AlertModal').modal('show');
  }

  closeAlertModel(modalId: string): void {
    $(modalId).modal('hide');
  }
}
