import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService, PopupsService } from '@common/services';
import { ChangePasswordLoad, LoginPayload, TokenResponse, User } from '@modules/auth/models';

import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AuthUtilsService } from './auth-utils.service';

@Injectable()
export class AuthService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private authUtilsService: AuthUtilsService,
        private router: Router,
        private popupsService: PopupsService
    ) {}

    login$(loginPayload: LoginPayload): Observable<boolean> {
        const httpOptions = {
            withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
          }; 

        return this.http
            .post<TokenResponse>(
                `${this.configService.config.sbCleanBlogNodeURL}/api/latest/auth/login`,
                loginPayload, httpOptions
            )
            .pipe(
                switchMap(
                    (loginResults): Observable<User> =>
                        this.authUtilsService.processToken$(loginResults.token)
                ),
                switchMap(user => {
                    return from(this.router.navigate(['/']));
                }),
                catchError((error: HttpErrorResponse) => {
                    this.popupsService.viewHttpErrorResponseMessagesInAlertModal(error, '#appError');
                    return EMPTY;
                }
                )
            );
    }

    changePassword$(changePasswordLoad: ChangePasswordLoad): Observable<undefined> {
        return this.http
            .put<undefined>(
                `${this.configService.config.sbCleanBlogNodeURL}/api/latest/auth/changePassword`,
                changePasswordLoad
            )
            .pipe(
                    tap(response => this.router.navigate([`/`])),
                    catchError((error: HttpErrorResponse) => {
                        this.popupsService.viewHttpErrorResponseMessagesInAlertModal(error, '#appError');
                        return EMPTY;
                    }
                    )
                );
    }
}
