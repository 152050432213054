<div class="fixed-bottom p-4">
  <div class="toast bg-dark text-white w-50 mw-100" role="alert" data-autohide="false">
      <div class="toast-body p-4 d-flex flex-column">
          <h4>Cookies Policy</h4>
          <p>
            We use cookies to improve your experience. By continuing, you agree to our use of cookies.
            <br>
            <br>
            Check our <a routerLink="/cookie-policy" class="text-warning">Cookie Policy</a> for more information.
          </p>
          <div class="ml-auto">
              <button type="button" class="btn btn-light" (click)="setCookieConsent(true)">
                  Accept
              </button>
          </div>
      </div>
  </div>
</div>