import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { UtilityService } from '@common/services';
import { CookieService } from 'ngx-cookie';
import { filter } from 'rxjs/operators';

declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'prodevline-blog';
    darkMode = false;
    showCookieBanner: boolean = true;
    
    constructor(
      public router: Router, 
      private titleService: Title, 
      private utilityService: UtilityService,
      private cookieService: CookieService
      ) {
        this.router.events
            .pipe(filter(event => event instanceof ChildActivationEnd))
            .subscribe(event => {
                let snapshot = (event as ChildActivationEnd).snapshot;
                while (snapshot.firstChild !== null) {
                    snapshot = snapshot.firstChild;
                }
                this.titleService.setTitle(snapshot.data.title || 'ProDevLine Blog');

                if (event instanceof NavigationEnd) {
                  gtag('config', 'G-27MN2GC6FE', { 'page_path': event.urlAfterRedirects });
                }  
            });
    }

    ngOnInit() {
      let consentCookie = this.cookieService.get("UserConsentCookie");
      
      // Check consent in order to add the Banner in the DOM
      if(consentCookie == "true") {
        this.showCookieBanner = false;
      }
      else {
        this.showCookieBanner = true;
      }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.utilityService.getStoredObject('darkModeSetting')) {
                this.darkMode = this.utilityService.getStoredObject('darkModeSetting') ?? false;
                if (this.darkMode) {
                  document.body.querySelectorAll('.post-title').forEach(item => item.classList.add('dark-theme-post-preview'));
                  document.body.querySelectorAll('.post-subtitle').forEach(item => item.classList.add('dark-theme-post-preview'));
                }
                else {
                  document.body.querySelectorAll('.post-title').forEach(item => item.classList.remove('dark-theme-post-preview'));
                  document.body.querySelectorAll('.post-subtitle').forEach(item => item.classList.remove('dark-theme-post-preview'));
                }
              }
              else {
                this.utilityService.storeObject('darkModeSetting', this.darkMode);
              }
          }, 1000);
    }
}
