import { Component, Input, OnInit } from '@angular/core';
import { ConsentPayload } from '@common/models/consent.model';
import { ConfigService } from '@common/services';
import { CookieService } from 'ngx-cookie';

declare var $:any;

@Component({
  selector: 'app-consent-banner',
  templateUrl: './consent-banner.component.html',
  styleUrls: ['./consent-banner.component.scss']
})
export class ConsentBannerComponent implements OnInit {

  @Input() modalId: string = "default";

  constructor(
    private configService: ConfigService,
    private cookieService: CookieService
    ) {
  }
  
  ngOnInit() {
    let consentCookie = this.cookieService.get("UserConsentCookie");

    if(consentCookie != "true") {
      $('.toast').toast('show');
    }
    else {
      $('.toast').toast('hide');
    }
  }

  setCookieConsent(consentValue: boolean): void {
    let temp: ConsentPayload = {
      consent: consentValue
    };

    this.configService
    .consent$(temp)
    .subscribe(() => {
      $('.toast').toast('hide');
  });
  }
}
